import React from "react"
import { graphql } from "gatsby"

const HomePage = ({ data }) => 

  <pre>{JSON.stringify(data, null, 4)}</pre>

export const query = graphql`
  {
    allContentfulProjects {
      edges {
        node {
          projectTitle
          year
          description {
            description
          }
        }
      }
    }
  }
`

export default HomePage

